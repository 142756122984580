import React from "react";
import "../styles/ArtDesign.css";
import HeroImage from "../media/self/projects-page_banner.jpg";
import ArtDesignIntro from "../components/ArtDesignIntro";
import ArtDesignGrid from "../components/ArtDesignGrid";

function ArtDesign() {
  return (
    <div className="design-page">
      <img
        src={HeroImage}
        alt="Sarah Kaydence_art & design hero shot"
        className="img-fluid page-banner"
      />

      <main className="container-fluid p-5">
        <ArtDesignIntro />

        <ArtDesignGrid />
      </main>
    </div>
  );
}

export default ArtDesign;
