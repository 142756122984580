import React from "react";

function ArtDesignIntro() {
  return (
    <div className="artdesign-intro py-4 text-center">
      <p className="fw-bold m-2">
        Here's a selection of projects I've worked on. Happy browsing! :)
      </p>
    </div>
  );
}

export default ArtDesignIntro;
