import React from "react";
import { Link } from "react-router-dom";
import ThumbnailTFC from "../media/projects/the-forage-cafe/the-forage-cafe_cover-image.png";
import Thumbnail13Honey from "../media/projects/13-honey/13H_NDP-IG_210728.jpg";
import ThumbnailShimeji from "../media/projects/shimeji/shimeji_cover-image.jpg";
import ThumbnailPOTS from "../media/projects/people-of-the-spirit/TheCity_PoTS_cover-image.jpg";

function ArtDesignRow3() {
  return (
    <div class="design-row row text-center">
      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/theforagecafe"
          title="The Forage Cafe page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailTFC}
              alt="project_the forage cafe"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">The Forage Cafe</h4>
        <small class="feature-category">
          Graphic Design | Art Direction | Digital Imaging
        </small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/13honey"
          title="13 Honey page"
        >
          <div className="wrapper">
            <img
              src={Thumbnail13Honey}
              alt="project_13 honey"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">13 Honey</h4>
        <small class="feature-category">
          Graphic Design | Art Direction | Digital Imaging
        </small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/shimeji"
          title="Shimeji page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailShimeji}
              alt="project_shimeji"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">Shimeji (Proposed)</h4>
        <small class="feature-category">
          Art Direction | Branding | Illustration
        </small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/peopleofthespirit"
          title="People of The Spirit page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailPOTS}
              alt="project_people of the spirit"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">People of The Spirit</h4>
        <small class="feature-category">
          Art Direction | Presentation Design | Illustration
        </small>
      </div>
    </div>
  );
}

export default ArtDesignRow3;
