import React from "react";
import HeroImage from "../media/projects/people-of-the-spirit/TheCity_PoTS_page-cover.jpg";
import Shot1 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Slides_title - 1-01.jpg";
import Shot2 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Slides_title - 1-02.jpg";
import Shot3 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Slides_title - 2.jpg";
import Shot4 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Slides_promo.jpg";
import Shot5 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Slides_quote.jpg";
import Shot6 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Podcast-01.jpg";
import Shot7 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Podcast-02.jpg";
import Shot8 from "../media/projects/people-of-the-spirit/TheCity_PoTS-Podcast-03.jpg";
import BackTo from "../components/BackTo";

function POTS() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of People of The Spirit subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">People of The Spirit</h3>
              <p className="project-tag">
                Art Direction | Presentation Design | Illustration
              </p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">People of The Spirit </span>was a
                sermon series campaign that ran in my home church in mid 2020.
                The focus of the series was to discuss on the following key
                topics: discipleship crisis, the Last Days, and subversive
                virtues for our culture.
                <br />
                <br />
                I co-speared the art direction of the campaign with my home
                church's Creative Team Head. We decided to go with a palette
                that evoked a warm, communal-friendly vibe. As for the
                iconography — I came up with a bunch of illustrations that
                represented certain elements described in the Bible, i.e. the
                sea of glass, bowls of incense, rainbows around the throne,
                tongues of fire.
                <br />
                <br />
                Below are some of the visuals created for the sermon series
                campaign, which include — the key visual, sermon slides and
                sermon podcast feature posts on Instagram.
              </p>
              <br />
              <a
                href="https://www.thecity.sg"
                target="_blank"
                rel="noreferrer"
                title="Link to The City Singapore's website"
                className="project-button text-decoration-none fw-bold"
              >
                thecity.sg
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-4">
              <img src={Shot1} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-4">
              <img src={Shot2} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-4">
              <img src={Shot3} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img src={Shot4} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot5} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <img src={Shot6} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-4">
              <img src={Shot7} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-4">
              <img src={Shot8} alt="" className="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default POTS;
