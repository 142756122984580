import React from "react";
import { videos } from "../data";

function MusicVideos() {
  return (
    <div className="music-videos">
      <h1 className="m-4 text-center text-uppercase">Videos</h1>
      <div className="videos-container container-fluid">
        <div className="row">
          {videos.map((video) => (
            <div className="col-12 col-sm-3 mt-3 mb-3">
              <div class="ratio ratio-16x9">
                <iframe
                  src={video.link}
                  title={video.title}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MusicVideos;
