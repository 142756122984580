import React from "react";

function MusicIntro() {
  return (
    <div className="music-intro py-4 text-center">
      <p className="m-2">
        Besides working full-time as a graphic designer, music is another
        passion of mine.
        <br />
        <br />
        I’ve been playing guitar ever since I was 15, and am still learning more
        about the instrument each day.
        <br />
        <br />
        <span className="fst-italic fw-bold">
          “Support music every day, because there isn’t a day music won’t be
          there for you!”
        </span>
        <br />
        <span className="fw-bold">― Hayley Williams</span>
      </p>
    </div>
  );
}

export default MusicIntro;
