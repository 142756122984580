import React from "react";
import "../styles/Music.css";
import HeroImage from "../media/self/guitar-shot-11_banner.jpg";
import MusicIntro from "../components/MusicIntro";
import MusicImages from "../components/MusicImages";
import MusicVideos from "../components/MusicVideos";

function Music() {
  return (
    <div className="music-page">
      <img
        src={HeroImage}
        alt="Sarah Kaydence_music hero shot"
        className="img-fluid page-banner"
      />

      <main className="container-fluid p-5">
        <MusicIntro />
        <MusicImages />
        <hr />
        <MusicVideos />
      </main>
    </div>
  );
}

export default Music;
