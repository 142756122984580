import React from "react";
import HeroImage from "../media/projects/god-of-games/GoG_Whole-1.JPG";
import Shot1 from "../media/projects/god-of-games/GoG_Guest-1.jpg";
import Shot2 from "../media/projects/god-of-games/GoG_Guest-2.jpg";
import Shot3 from "../media/projects/god-of-games/GoG_Guest-3.jpg";
import Shot4 from "../media/projects/god-of-games/GoG_Guest-4.jpg";
import Shot5 from "../media/projects/god-of-games/GoG_Guest-5.jpg";
import Shot6 from "../media/projects/god-of-games/GoG_Guest-6.jpg";
import Shot7 from "../media/projects/god-of-games/GoG_Closeup-1.JPG";
import Shot8 from "../media/projects/god-of-games/GoG_Closeup-2.JPG";
import Shot9 from "../media/projects/god-of-games/GoG_Closeup-3.JPG";
import Shot10 from "../media/projects/god-of-games/GoG_Closeup-4.JPG";
import Shot11 from "../media/projects/god-of-games/GoG_Closeup-5.JPG";
import Shot12 from "../media/projects/god-of-games/GoG_Closeup-6.JPG";
import Shot13 from "../media/projects/god-of-games/GoG_Closeup-7.JPG";
import Shot14 from "../media/projects/god-of-games/GoG_Closeup-8.JPG";
import BackTo from "../components/BackTo";

function GodOfGames() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of God of Games subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                Inner Mettle
                <br />
                (God of Games)
              </h3>
              <p className="project-tag">
                Installation Design | Wirecraft | Kitbashing
              </p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">God of Games </span>was an art
                exhibition hosted by Singapore Chinese Cultural Centre, as part
                of their REMIX 2K22 youth festival. <br />
                <br />
                My piece is titled
                <span className="fw-bold"> “Inner Mettle”</span>, and it is a
                kinetic representation of us going through the motions of life.
                Time always presses play, with no chance for us to hit pause,
                stop or rewind. All we can do, is keep moving forward. The
                highs, the lows, the twists and turns we get caught in — they
                all come together to form the unique mixtape of our life’s
                story. Ultimately, we all end up at the same destination — and
                the beauty of what happens in the meantime, is all that matters.{" "}
                <br />
                <br />
                The installation is a rolling ball sculpture, with intersecting
                and duelling tracks. As the marbles roll down the tracks, there
                will be banks and drops, as well as quirky segments that involve
                or references metal music instruments. <br />
                <br />
                Visitors can either treat it as a race to the bottom against
                their peers, or simply interact with the ball run purely as a
                kinetic-auditory experience.
                <br />
                <br />
                (Photo credits: Sarah Kaydence, Singapore Chinese Cultural
                Centre)
              </p>

              <br />
              <a
                href="https://singaporeccc.org.sg/events/god-of-games-remix-2k22/"
                target="_blank"
                rel="noreferrer"
                title="Link to God of Games's microsite"
                className="project-button text-decoration-none fw-bold"
              >
                More about God of Games
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-6 mb-4">
              <img src={Shot1} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot2} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot3} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot4} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot5} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot6} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col-6 mb-4">
              <img src={Shot7} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot8} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot9} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot10} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col-6 mb-4">
              <img src={Shot11} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot12} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot13} alt="" className="img-fluid" />
            </div>
            <div className="col-6 mb-4">
              <img src={Shot14} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube-nocookie.com/embed/iuZlTHCp7ZM"
                  title="God Of Games | Behind The Scenes"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default GodOfGames;
