import React from "react";
import { experiences } from "../data";

function AboutExperience() {
  return (
    <div className="about-experience">
      <h1 className="mt-5 mb-3 text-center text-uppercase">Experiences</h1>
      <div className="container-fluid">
        <div className="row mt-4">
          {experiences.map((experience) => (
            <div className="col-md-4 mt-4 mb-3 text-center experience-info">
              <p>
                <span className="experience-title">{experience.jobtitle}</span>
                <br />
                {experience.company}
                <br />
                <span className="experience-duration">
                  {experience.timeline}
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutExperience;
