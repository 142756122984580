import React from "react";
import { Link } from "react-router-dom";
import ThumbnailSomerset from "../media/projects/comic-connect/smrt-somerset_cover-image.jpg";
import ThumbnailGoG from "../media/projects/god-of-games/GoG_Whole-2 thumbnail.JPG";
import ThumbnailReactDictionary from "../media/projects/react-dictionary-app/react-dictionary-app_cover-image.png";
import ThumbnailReactWeather from "../media/projects/react-weather-app/react-weather-app_cover-image.png";

function ArtDesignRow1() {
  return (
    <div class="design-row row text-center">
      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/comicconnect"
          title="Comic Connect page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailSomerset}
              alt="project_comic connect"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">
          Comic Connect:
          <br />
          Somerset
        </h4>
        <small class="feature-category">Illustration</small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/godofgames"
          title="God of Games page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailGoG}
              alt="project_god of games"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">
          Inner Mettle
          <br />
          (God of Games)
        </h4>
        <small class="feature-category">
          Art Installation | Wirecraft | Kitbashing
        </small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/reactdictionary"
          title="React Dictionary App page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailReactDictionary}
              alt="project_react dictionary app"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">
          Dictionary App
          <br />
          (React.js)
        </h4>
        <small class="feature-category">
          HTML | Bootstrap CSS | React.js | AJAX
        </small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/reactweather"
          title="React Weather App page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailReactWeather}
              alt="project_react weather app"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">
          Weather App
          <br />
          (React.js)
        </h4>
        <small class="feature-category">
          HTML | Bootstrap CSS | React.js | AJAX
        </small>
      </div>
    </div>
  );
}

export default ArtDesignRow1;
