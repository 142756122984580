import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import ArtDesign from "./pages/ArtDesign";
import Music from "./pages/Music";
import GodOfGames from "./pages/GodOfGames";
import ReactDictionary from "./pages/ReactDictionary";
import ReactWeather from "./pages/ReactWeather";
import VanillaWeather from "./pages/VanillaWeather";
import RespPortfolio from "./pages/RespPortfolio";
import SWPage from "./pages/SWPage";
import MusicBoxPedals from "./pages/MusicBoxPedals";
import TheForageCafe from "./pages/TheForageCafe";
import ThirteenHoney from "./pages/13Honey";
import Shimeji from "./pages/Shimeji";
import POTS from "./pages/POTS";
import CampXL from "./pages/CampXL";
import NinetySeconds from "./pages/90Seconds";
import LKYSPP from "./pages/LKYSPP";
import OdyIliad from "./pages/OdyIliad";
import Macbeth from "./pages/Macbeth";
import ClemElysia from "./pages/ClemElysia";
import SWXmas from "./pages/SWXmas";
import EbilDevices from "./pages/EbilDevices";
import ComicConnect from "./pages/ComicConnect";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/artdesign" element={<ArtDesign />} />
          <Route path="/artdesign/comicconnect" element={<ComicConnect />} />
          <Route path="/artdesign/godofgames" element={<GodOfGames />} />
          <Route
            path="/artdesign/reactdictionary"
            element={<ReactDictionary />}
          />
          <Route path="/artdesign/reactweather" element={<ReactWeather />} />
          <Route
            path="/artdesign/vanillaweather"
            element={<VanillaWeather />}
          />
          <Route
            path="/artdesign/responsiveportfolio"
            element={<RespPortfolio />}
          />
          <Route path="/artdesign/starwarslandingpage" element={<SWPage />} />
          <Route
            path="/artdesign/musicboxpedals"
            element={<MusicBoxPedals />}
          />
          <Route path="/artdesign/theforagecafe" element={<TheForageCafe />} />
          <Route path="/artdesign/13honey" element={<ThirteenHoney />} />
          <Route path="/artdesign/shimeji" element={<Shimeji />} />
          <Route path="/artdesign/peopleofthespirit" element={<POTS />} />
          <Route path="/artdesign/campxl" element={<CampXL />} />
          <Route path="/artdesign/90seconds" element={<NinetySeconds />} />
          <Route path="/artdesign/lkyspp" element={<LKYSPP />} />
          <Route path="/artdesign/odysseyiliad" element={<OdyIliad />} />
          <Route path="/artdesign/macbeth" element={<Macbeth />} />
          <Route path="/artdesign/clementelysia" element={<ClemElysia />} />
          <Route path="/artdesign/starwarsxmas" element={<SWXmas />} />
          <Route path="/artdesign/ebildevices" element={<EbilDevices />} />
          <Route path="/music" element={<Music />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
