import React from "react";
import { designskills } from "../data";
import { codingskills } from "../data";

function AboutSkills() {
  return (
    <section className="skills mb-5">
      <h1 className="mt-5 mb-3 text-center text-uppercase">Skillsernal</h1>
      <h4 className="mb-5 text-center">(Skill + Arsenal... geddit?)</h4>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 justify-content-center">
            <h4 className="mb-4 skill-category text-center text-decoration-underline">
              Art and Design
            </h4>

            <div className="row mb-4 skill-list">
              {designskills.map((designskill) => (
                <div className="col-md-4 mt-4 mb-3 text-center skill-name">
                  <p>{designskill}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="col-6 justify-content-center">
            <h4 className="mb-4 skill-category text-center text-decoration-underline">
              Front-End Development
            </h4>

            <div className="row mb-4 skill-list">
              {codingskills.map((codingskill) => (
                <div className="col-md-4 mt-4 mb-3 text-center skill-name">
                  <p>{codingskill}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSkills;
