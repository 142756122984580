import React from "react";
import HeroImage from "../media/projects/the-forage-cafe/the-forage-cafe_page-cover.jpg";
import Shot1 from "../media/projects/the-forage-cafe/HONEY SWICH CLUB_210812-IG-01.jpg";
import Shot2 from "../media/projects/the-forage-cafe/TFC-XMAS21_Promo IG 211201-FA.png";
import Shot3 from "../media/projects/the-forage-cafe/TFC-MCF21_Promo IG 210816-01.png";
import Shot4 from "../media/projects/the-forage-cafe/TFC_Square-2021 RSVP DineIn_FA.jpg";
import Shot5 from "../media/projects/the-forage-cafe/$1.90 COFFEE TOPUP_IG.png";
import Shot6 from "../media/projects/the-forage-cafe/TFC_2021 NDP-IG.jpg";
import Shot7 from "../media/projects/the-forage-cafe/2 PLUS 1-CAKES-01.jpg";
import Shot8 from "../media/projects/the-forage-cafe/COLLECTION-DELIVERY_IG_210721.png";
import Shot9 from "../media/projects/the-forage-cafe/TFC_CNY22-A4.jpg";
import Shot10 from "../media/projects/the-forage-cafe/TFC_VDAY22-A4.jpg";
import Shot11 from "../media/projects/the-forage-cafe/TFC_CNY22-WEB BANNER.png";
import Shot12 from "../media/projects/the-forage-cafe/TFC_VDAY22-WEB BANNER.png";
import BackTo from "../components/BackTo";

function TheForageCafe() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of The Forage Cafe subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">The Forage Cafe</h3>
              <p className="project-tag">
                Graphic Design | Art Direction | Digital Imaging
              </p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">The Forage Cafe </span>is Singapore's
                first honey-themed cafe, created by the folks behind 13 Honey.
                The Forage Cafe aspires to be the desired place to hunt (or
                "forage") for honey-infused dishes, desserts and drinks.
              </p>
              <br />
              <a
                href="https://www.instagram.com/theforagecafesg/"
                target="_blank"
                rel="noreferrer"
                title="Link to The Forage Cafe's Instagram"
                className="project-button text-decoration-none fw-bold"
              >
                @theforagecafesg
              </a>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col col-lg-6 mb-4">
              <img src={Shot1} alt="" class="img-fluid" />
            </div>
            <div class="col col-lg-6 mb-4">
              <img src={Shot2} alt="" class="img-fluid" />
            </div>
          </div>

          <div class="row">
            <div class="col col-lg-6 mb-4">
              <img src={Shot3} alt="" class="img-fluid" />
            </div>
            <div class="col col-lg-6 mb-4">
              <img src={Shot4} alt="" class="img-fluid" />
            </div>
          </div>

          <div class="row">
            <div class="col-6 col-lg-3 mb-4">
              <img src={Shot5} alt="" class="img-fluid" />
            </div>
            <div class="col-6 col-lg-3 mb-4">
              <img src={Shot6} alt="" class="img-fluid" />
            </div>
            <div class="col-6 col-lg-3 mb-4">
              <img src={Shot7} alt="" class="img-fluid" />
            </div>
            <div class="col-6 col-lg-3 mb-4">
              <img src={Shot8} alt="" class="img-fluid" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-4">
              <img src={Shot9} alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6 mb-4">
              <img src={Shot10} alt="" class="img-fluid" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot11} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot12} alt="" class="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default TheForageCafe;
