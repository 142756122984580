import React from "react";
import { Link } from "react-router-dom";
import ThumbnailMacbeth from "../media/projects/macbeth/macbeth_cover-image.jpg";
import ThumbnailMoonEly from "../media/projects/clement-elysia/clement-elysia_cover-image.jpeg";
import ThumbnailSWXmas from "../media/projects/sw-xmas/sw-xmas_cover-image1.JPG";
import ThumbnailEbilDevices from "../media/projects/ebil-devices/ebil-devices_cover-image.jpg";

function ArtDesignRow1() {
  return (
    <div className="design-row row text-center">
      <div className="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/macbeth"
          title="Macbeth page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailMacbeth}
              alt="project_macbeth"
              className="img-fluid"
            />
          </div>
        </Link>

        <h4 className="mt-4 mb-2">Macbeth</h4>
        <small className="feature-category">
          Editorial Design | Art Direction | Illustration
        </small>
      </div>

      <div className="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/clementelysia"
          title="Clement and Elysia page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailMoonEly}
              alt="project_clement and elysia"
              className="img-fluid"
            />
          </div>
        </Link>

        <h4 className="mt-4 mb-2">Clement and Elysia</h4>
        <small className="feature-category">Illustration</small>
      </div>

      <div className="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/starwarsxmas"
          title="Star Wars Christmas cards page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailSWXmas}
              alt="project_may the christmas be with you"
              className="img-fluid"
            />
          </div>
        </Link>

        <h4 className="mt-4 mb-2">
          May The Christmas
          <br />
          Be With You
        </h4>
        <small className="feature-category">Illustration</small>
      </div>

      <div className="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/ebildevices"
          title="Ebil Devices page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailEbilDevices}
              alt="project_ebil devices"
              className="img-fluid"
            />
          </div>
        </Link>

        <h4 className="mt-4 mb-2">Ebil Devices</h4>
        <small className="feature-category">
          Packaging Design | Illustration
        </small>
      </div>
    </div>
  );
}

export default ArtDesignRow1;
