import React from "react";
import HeroImage from "../media/projects/odyssey-iliad/odyssey-iliad_page-cover.jpg";
import Shot1 from "../media/projects/odyssey-iliad/odyssey_cover.JPG";
import Shot2 from "../media/projects/odyssey-iliad/odyssey_inside-1.JPG";
import Shot3 from "../media/projects/odyssey-iliad/odyssey_inside-2.JPG";
import Shot4 from "../media/projects/odyssey-iliad/odyssey_inside-3.JPG";
import Shot5 from "../media/projects/odyssey-iliad/odyssey_inside-4.JPG";
import Shot6 from "../media/projects/odyssey-iliad/iliad_cover.JPG";
import Shot7 from "../media/projects/odyssey-iliad/iliad_inside-1.JPG";
import Shot8 from "../media/projects/odyssey-iliad/iliad_inside-2.JPG";
import BackTo from "../components/BackTo";

function OdyIliad() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of The Odyssey and The Iliad subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                The Odyssey
                <br />
                and The Iliad
              </h3>
              <p className="project-tag">Book Design | Illustration</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                The concept behind my take on
                <span className="fw-bold"> The Odyssey and The Iliad </span>is
                interconnectedness — both books are in the same narrative
                universe, so I wanted to show that link through the covers. The
                arrow that Odysseus shoots during his trial, “travels” over to
                target Achilles who is prepared to block it.
                <br />
                <br />
                To further reinforce the stories’ connection, I made the books a
                “2-in-1” book, and also to make it easy for readers to hop
                between stories, instead of carrying 2 separate novels.
                <br />
                <br />
                Lastly, the inside flaps of both books show the locations
                visited by the characters and the overall journeys undertaken.
                <br />
                <br />
                The books are bound & kettle-stitched by hand.
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <img src={Shot1} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img src={Shot2} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot3} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img src={Shot4} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot5} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <img src={Shot6} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img src={Shot7} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot8} alt="" className="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default OdyIliad;
