export const designskills = [
  "Hand-drawn Illustration",
  "Presentation Design",
  "Print Design",
  "Brand Implementation",
  "Photo Editing",
  "Digital Imaging",
  "Adobe Photoshop",
  "Adobe Illustrator",
  "Adobe InDesign",
];

export const codingskills = [
  "React.js",
  "Bootstrap CSS",
  "HTML5",
  "VanillaJS",
  "CodeSandbox",
  "Visual Studio Code",
  "Invision Freehand",
  "Figma",
  "Framer",
];

export const experiences = [
  {
    jobtitle: "Senior Graphic Designer / Junior Graphic Designer",
    company: "Playgroup Pte Ltd",
    timeline: "Mar 2021 - Dec 2022 / Jan 2019 - Feb 2021",
  },
  {
    jobtitle: "Graphic Designer and Illustrator",
    company: "The Schooling Society",
    timeline: "Jan - Dec 2018",
  },
  {
    jobtitle: "Graphic Designer",
    company: "90 Seconds",
    timeline: "Sep - Dec 2017",
  },
  {
    jobtitle: "Graphic Design (Marketing) Intern",
    company: "That Marketing Guy",
    timeline: "May - Jun 2017",
  },
  {
    jobtitle: "Design Intern",
    company: "OuterEdit",
    timeline: "May - Dec 2015",
  },
  {
    jobtitle: "Presentation Maverick Intern",
    company: "Highspark",
    timeline: "Oct 2014 - Jan 2015",
  },
];

export const videos = [
  {
    link: "https://www.youtube-nocookie.com/embed/8zC7Xpz_Kz0",
    title: "Freedom Is Here - Hillsong United (GE Student Cover - Sarah Ong)",
  },
  {
    link: "https://www.youtube-nocookie.com/embed/GUaYZyDLFMQ",
    title: "Worship At The City | 16 Oct 2022 | The City",
  },
  {
    link: "https://www.youtube-nocookie.com/embed/JxXsXn3XgQA",
    title: "Worship At The City | 02 Oct 2022 | The City",
  },
  {
    link: "https://www.youtube-nocookie.com/embed/sZNgqiOEgMo",
    title: "Worship At The City | 11 Sep 2022 | The City",
  },
  {
    link: "https://www.youtube-nocookie.com/embed/oVFdhWrRtQw",
    title: "Worship At The City | 21 Aug 2022 | The City",
  },
  {
    link: "https://www.youtube-nocookie.com/embed/1wnfcEIa6nU",
    title: "Worship At The City | 03 Jul 2022 | The City",
  },
  {
    link: "https://www.youtube-nocookie.com/embed/QuBZPAUlWfs",
    title: "Worship At The City | 26 Jun 2022 | The City",
  },
  {
    link: "https://www.youtube-nocookie.com/embed/glSiXGdeJtg",
    title: "Worship At The City | 03 Apr 2022 | The City",
  },
];
