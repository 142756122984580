import React from "react";
import "../styles/Home.css";
import HomeWelcome from "../components/HomeWelcome";
import HomeFeatured from "../components/HomeFeatured";

function Home() {
  return (
    <div className="home-page">
      <main className="container-fluid p-5">
        <HomeWelcome />
        <hr />
        <HomeFeatured />
      </main>
    </div>
  );
}

export default Home;
