import React from "react";
import BackTo from "../components/BackTo";
import HeroImage from "../media/projects/responsive-portfolio/portfolio-website_web-mock.jpg";

function RespPortfolio() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Responsive Portfolio subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">Responsive Portfolio</h3>
              <p className="project-tag">HTML | Bootstrap CSS | Flexbox</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">SheCodes Responsive </span>is a 5-week
                part-time course that I attended, from February to March 2022.
                During the course, I learnt how to build multi-page responsive
                websites, using tools and techniques like advanced Bootstrap
                (Navigation, Utility Classes, etc.), Flexbox, Multiple Page
                Hosting, Media Queries, and more.
                <br />
                <br />
                <span className="fw-bold">Languages: </span>HTML5 and CSS
                <br />
                <span className="fw-bold">Libraries / Frameworks: </span>
                Bootstrap
              </p>
              <br />
              <a
                href="https://illustrious-blini-e955bd.netlify.app"
                target="_blank"
                rel="noreferrer"
                title="Link to Responsive Portfolio"
                className="project-button text-decoration-none fw-bold"
              >
                Give the site a spin!
              </a>
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default RespPortfolio;
