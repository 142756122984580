import React from "react";
import HeroImage from "../media/projects/sw-xmas/sw-xmas_page-cover.jpg";
import Shot1 from "../media/projects/sw-xmas/sw-xmas_2019-01.png";
import Shot2 from "../media/projects/sw-xmas/sw-xmas_2017-02.jpg";
import Shot3 from "../media/projects/sw-xmas/sw-xmas_2017-03.jpg";
import Shot4 from "../media/projects/sw-xmas/sw-xmas_2017-01.jpg";
import Shot5 from "../media/projects/sw-xmas/sw-xmas_2018-01.jpg";
import Shot6 from "../media/projects/sw-xmas/sw-xmas_2017-04.jpg";
import Shot7 from "../media/projects/sw-xmas/sw-xmas_2018-04.jpg";
import Shot8 from "../media/projects/sw-xmas/sw-xmas_2018-02.jpg";
import Shot9 from "../media/projects/sw-xmas/sw-xmas_2018-03.jpg";
import BackTo from "../components/BackTo";

function SWXmas() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of May The Christmas Be With You subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                May The Christmas
                <br />
                Be With You
              </h3>
              <p className="project-tag">Illustration</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                When the Disney-era of Star Wars films (a.k.a. the Sequels) were
                released back in 2015 to 2019, most (if not all) of the films
                were released during the Christmas period.
                <br />
                <br />I decide to join in the festivities, by creating my own
                mini-series of
                <span className="fw-bold">Star Wars-meets-Christmas </span>
                greeting cards — to gift to my friends for the holidays.
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <img
                src={Shot1}
                alt="2019's edition — Kuiil, The Child (Baby Yoda) and The Mandalorian"
                className="img-fluid mb-4"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img
                src={Shot2}
                alt="2017's edition — R2-D2 and BB-8"
                className="img-fluid mb-4"
              />
            </div>
            <div className="col-lg-6">
              <img
                src={Shot3}
                alt="2017's edition — Chewbacca and Wicket"
                className="img-fluid mb-4"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img
                src={Shot4}
                alt="2017's edition — Tauntaun and Wampa"
                className="img-fluid mb-4"
              />
            </div>
            <div className="col-lg-6">
              <img
                src={Shot5}
                alt="2018's edition — Santa the Hutt"
                className="img-fluid mb-4"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img
                src={Shot6}
                alt="2017's edition — Rey"
                className="img-fluid mb-4"
              />
            </div>
            <div className="col-lg-6">
              <img
                src={Shot7}
                alt="2018's edition — Jango Bells"
                className="img-fluid mb-4"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img
                src={Shot8}
                alt="2018's edition — Emperor Santatine"
                className="img-fluid mb-4"
              />
            </div>
            <div className="col-lg-6">
              <img
                src={Shot9}
                alt="2018's edition — Darth Vadolph"
                className="img-fluid mb-4"
              />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default SWXmas;
