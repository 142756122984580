import React from "react";
import BasicsCert from "../media/certs/SheCodes Basics | Cert.png";
import PlusCert from "../media/certs/SheCodes Plus | Cert.png";
import RespCert from "../media/certs/SheCodes Responsive | Cert.png";
import ReactCert from "../media/certs/SheCodes React | Cert.png";

function AboutCerts() {
  return (
    <div className="about-certs container-fluid mb-5">
      <h1 class="mt-5 mb-3 text-center text-uppercase">Certifications</h1>
      <div class="container-fluid">
        <div class="row mt-4 px-4">
          <div class="col-md-3">
            <img
              src={BasicsCert}
              alt="Certificate — SheCodes Basics"
              class="img-fluid shadow mb-3"
            />
          </div>
          <div class="col-md-3">
            <img
              src={PlusCert}
              alt="Certificate — SheCodes Plus"
              class="img-fluid shadow mb-3"
            />
          </div>
          <div class="col-md-3">
            <img
              src={RespCert}
              alt="Certificate — SheCodes Responsive"
              class="img-fluid shadow mb-3"
            />
          </div>
          <div class="col-md-3">
            <img
              src={ReactCert}
              alt="Certificate — SheCodes React"
              class="img-fluid shadow"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutCerts;
