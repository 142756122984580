import React from "react";
import HeroImage from "../media/projects/music-box-pedals/music-box-pedals_page-cover.jpg";
import Shot1 from "../media/projects/music-box-pedals/20220218_114233.jpg";
import Shot2 from "../media/projects/music-box-pedals/20220427_152754 (1).jpg";
import BackTo from "../components/BackTo";

function MusicBoxPedals() {
  return (
    <div className="project-page">
      <section className="project-main col-lg-6 mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Music Box Pedals subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm">
              <h3 className="project-title">Music Box Pedals</h3>
              <p className="project-tag">Illustration</p>
            </div>

            <div className="col-sm">
              <p className="project-description">
                <span className="fw-bold">Music Box Pedals </span>is a guitar
                pedal company based in New Jersey, USA. It is a small business
                owned by Rob Farrell, who solely handles the entire operation —
                from handmaking and handwiring the effects pedals, to online
                marketing etc.
                <br />
                <br />
                Pedal art has been something that I've wanted to try, ever since
                I got sucked into the black hole of guitar pedals, but I just
                never knew how to break into that scene.
                <br />
                <br />
                What started off as a product inquiry, became an opportunity for
                me to make that dream a reality. Rob commissioned me to
                illustrate a graphic for his latest release — the Klone Drive.
                <br />
                <br />
                I'm forever grateful to Rob for taking a chance on me, and I'm
                looking forward to what we'll create together in the future!
                <br />
                <br />
                (Photo credits: Rob Farrell, Rick Calhoun)
              </p>
              <br />
              <a
                href="https://www.instagram.com/musicboxpedals/"
                target="_blank"
                rel="noreferrer"
                title="Link to MusicBoxPedals's Instagram"
                className="project-button text-decoration-none fw-bold"
              >
                @musicboxpedals
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col col-lg-6 mb-4">
              <img src={Shot1} alt="" className="img-fluid" />
            </div>
            <div className="col col-lg-6 mb-4">
              <img src={Shot2} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default MusicBoxPedals;
