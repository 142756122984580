import React from "react";
import HeroImage from "../media/projects/camp-xl/camp-xl_page-cover.jpg";
import Shot1 from "../media/projects/camp-xl/camp-xl_logos.jpg";
import Shot2 from "../media/projects/camp-xl/camp-xl_stickers.jpg";
import Shot3 from "../media/projects/camp-xl/camp-xl_tags.jpg";
import Shot4 from "../media/projects/camp-xl/camp-xl_backdrop.jpg";
import Shot5 from "../media/projects/camp-xl/camp-xl_spread1.jpg";
import Shot6 from "../media/projects/camp-xl/camp-xl_spread2.jpg";
import Shot7 from "../media/projects/camp-xl/camp-xl_spread3.jpg";
import Shot8 from "../media/projects/camp-xl/camp-xl_order-slip1.jpg";
import Shot9 from "../media/projects/camp-xl/camp-xl_order-slip2.jpg";
import BackTo from "../components/BackTo";

function CampXL() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Camp XL subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">Camp XL</h3>
              <p className="project-tag">
                Art Direction | Branding | Graphic Design
              </p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span class="fw-bold">Camp XL </span>was the name for my home
                church's annual church camp in mid 2019.
                <br />
                <br />
                When I first heard of the proposed name, the first thought that
                came to my mind was the concept of upsizing in fast food
                culture. Hence, for the art direction — I decided to go with a
                60/70's vintage diner aesthetic for all camp theming & marketing
                paraphernalia.
                <br />
                <br />
                Below are some of the assets created by me for the camp, which
                include — guerilla marketing "order chits", camp booklet and
                camper & committee tags.
              </p>
              <br />
              <a
                href="https://www.thecity.sg"
                target="_blank"
                rel="noreferrer"
                title="Link to The City Singapore's website"
                className="project-button text-decoration-none fw-bold"
              >
                thecity.sg
              </a>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col">
              <img src={Shot1} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot2} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot3} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot4} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <img src={Shot5} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot6} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot7} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <img src={Shot8} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-6">
              <img src={Shot9} alt="" class="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default CampXL;
