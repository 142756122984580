import React from "react";
import "../styles/About.css";
import AboutIntro from "../components/AboutIntro";
import AboutSkills from "../components/AboutSkills";
import AboutCerts from "../components/AboutCerts";
import AboutExperience from "../components/AboutExperience";

function About() {
  return (
    <div className="about-page">
      <main className="container-fluid p-5">
        <AboutIntro />
        <hr />
        <AboutSkills />
        <hr />
        <AboutCerts />
        <hr />
        <AboutExperience />
      </main>
    </div>
  );
}

export default About;
