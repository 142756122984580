import React from "react";
import HeroImage from "../media/projects/lkyspp/lkyspp_page-cover.jpg";
import Shot1 from "../media/projects/lkyspp/lkyspp_model-2.jpg";
import Shot2 from "../media/projects/lkyspp/lkyspp_model-3.jpg";
import Shot3 from "../media/projects/lkyspp/lkyspp_spread-1.jpg";
import Shot4 from "../media/projects/lkyspp/lkyspp_spread-2.jpg";
import Shot5 from "../media/projects/lkyspp/lkyspp_spread-3.jpg";
import BackTo from "../components/BackTo";

function LKYSPP() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Lee Kuan Yew School of Public Policy subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                Lee Kuan Yew
                <br />
                School of Public Policy
              </h3>
              <p className="project-tag">Editorial Design | Book Design</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                A yearbook I assisted in designing & producing for the
                <span className="fw-bold">
                  {" "}
                  Lee Kuan Yew School of Public Policy{" "}
                </span>
                class of 2014/15, during my internship with OuterEdit in 2015.
                <br />
                <br />
                (Photo credits: OuterEdit)
              </p>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6">
              <img src={Shot1} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot2} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <img src={Shot3} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-4">
              <img src={Shot4} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-4">
              <img src={Shot5} alt="" className="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default LKYSPP;
