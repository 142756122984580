import React from "react";
import ArtDesignRow1 from "../components/ArtDesignRow1";
import ArtDesignRow2 from "../components/ArtDesignRow2";
import ArtDesignRow3 from "../components/ArtDesignRow3";
import ArtDesignRow4 from "../components/ArtDesignRow4";
import ArtDesignRow5 from "../components/ArtDesignRow5";

function ArtDesignGrid() {
  return (
    <div className="design-projects mt-5">
      <ArtDesignRow1 />
      <ArtDesignRow2 />
      <ArtDesignRow3 />
      <ArtDesignRow4 />
      <ArtDesignRow5 />
    </div>
  );
}

export default ArtDesignGrid;
