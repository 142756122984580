import React from "react";
import BackTo from "../components/BackTo";
import HeroImage from "../media/projects/weather-app/weather-app_web-mock.png";

function VanillaWeather() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Weather App subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                Weather App
                <br />
                (VanillaJS)
              </h3>
              <p className="project-tag">HTML | CSS | VanillaJS | AJAX</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">SheCodes Plus </span>is a 8-week
                part-time course that I attended, from December 2021 to January
                2022. I learnt about advanced front-end development, as well as
                development tools like GitHub, Netlify and Bootstrap.
                <br />
                <br />
                I built this weather app, as my graduating final project for the
                course.
                <br />
                <br />
                <span className="fw-bold">Languages: </span>HTML5, CSS, and
                Javascript
                <br />
                <span className="fw-bold">Libraries / Frameworks: </span>
                Bootstrap
                <br />
                <span className="fw-bold">API: </span>
                <a
                  href="https://openweathermap.org/api"
                  target="_blank"
                  rel="noreferrer"
                  title="Link to weather API"
                  className="portfolio-link"
                >
                  OpenWeather
                </a>
              </p>
              <br />
              <a
                href="https://confident-borg-a08631.netlify.app"
                target="_blank"
                rel="noreferrer"
                title="Link to Weather App"
                className="project-button text-decoration-none fw-bold"
              >
                Give the app a try!
              </a>
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default VanillaWeather;
