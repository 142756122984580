import React from "react";
import { Link } from "react-router-dom";
import ThumbnailSomerset from "../media/projects/comic-connect/smrt-somerset_cover-image.jpg";
import ThumbnailGoG from "../media/projects/god-of-games/GoG_Whole-2 thumbnail.JPG";
import ThumbnailReactDictionary from "../media/projects/react-dictionary-app/react-dictionary-app_cover-image.png";
import ThumbnailReactWeather from "../media/projects/react-weather-app/react-weather-app_cover-image.png";

function Featured() {
  return (
    <div className="home-featured m-5">
      <h1>What I've been up to...</h1>

      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-3 mb-5">
            <Link
              className="wrapper-link"
              to="/artdesign/comicconnect"
              title="Comic Connect page"
            >
              <div className="wrapper">
                <img
                  src={ThumbnailSomerset}
                  alt="featured project_comic connect"
                  className="img-fluid"
                />
              </div>
            </Link>

            <h4 className="mt-4 mb-2">
              Comic Connect:
              <br />
              Somerset
            </h4>
            <small className="feature-category">Illustration</small>
          </div>

          <div className="col-md-3 mb-5">
            <Link
              className="wrapper-link"
              to="/artdesign/godofgames"
              title="God of Games page"
            >
              <div className="wrapper">
                <img
                  src={ThumbnailGoG}
                  alt="featured project_god of games"
                  className="img-fluid"
                />
              </div>
            </Link>

            <h4 className="mt-4 mb-2">
              Inner Mettle
              <br />
              (God of Games)
            </h4>
            <small className="feature-category">
              Art Installation | Wirecraft | Kitbashing
            </small>
          </div>

          <div className="col-md-3 mb-5">
            <Link
              className="wrapper-link"
              to="/artdesign/reactdictionaryapp"
              title="React Dictionary App page"
            >
              <div className="wrapper">
                <img
                  src={ThumbnailReactDictionary}
                  alt="featured project_react dictionary app"
                  className="img-fluid"
                />
              </div>
            </Link>

            <h4 className="mt-4 mb-2">
              Dictionary App
              <br />
              (React.js)
            </h4>
            <small className="feature-category">
              HTML | Bootstrap CSS | React.js | AJAX
            </small>
          </div>

          <div className="col-md-3 mb-5">
            <Link
              className="wrapper-link"
              to="/artdesign/reactweatherapp"
              title="React Weather App page"
            >
              <div className="wrapper">
                <img
                  src={ThumbnailReactWeather}
                  alt="featured project_react weather app"
                  className="img-fluid"
                />
              </div>
            </Link>

            <h4 className="mt-4 mb-2">
              Weather App
              <br />
              (React.js)
            </h4>
            <small className="feature-category">
              HTML | Bootstrap CSS | React.js | AJAX
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;
