import React from "react";
import HeroImage from "../media/projects/macbeth/macbeth_page-cover.jpg";
import Shot1 from "../media/projects/macbeth/macbeth_show-poster.jpg";
import Shot2 from "../media/projects/macbeth/macbeth_mediakit-cover.jpg";
import Shot3 from "../media/projects/macbeth/macbeth_mediakit-spread1.jpg";
import Shot4 from "../media/projects/macbeth/macbeth_mediakit-spread2.jpg";
import Shot5 from "../media/projects/macbeth/macbeth_mediakit-spread3.jpg";
import Shot6 from "../media/projects/macbeth/macbeth_mediakit-spread5.jpg";
import Shot7 from "../media/projects/macbeth/macbeth_mediakit-spread6.jpg";
import Shot8 from "../media/projects/macbeth/macbeth_mediakit-spread7.jpg";
import Shot9 from "../media/projects/macbeth/macbeth_styleguide-cover.jpg";
import Shot10 from "../media/projects/macbeth/macbeth_styleguide-spread1.jpg";
import Shot11 from "../media/projects/macbeth/macbeth_styleguide-spread2.jpg";
import Shot12 from "../media/projects/macbeth/macbeth_styleguide-spread3.jpg";
import Shot13 from "../media/projects/macbeth/macbeth_styleguide-spread4.jpg";
import Shot14 from "../media/projects/macbeth/macbeth_styleguide-spread5.jpg";
import Shot15 from "../media/projects/macbeth/macbeth_styleguide-spread6.jpg";
import BackTo from "../components/BackTo";

function Macbeth() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Macbeth subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">Macbeth</h3>
              <p className="project-tag">
                Graphic Design | Art Direction | Illustration
              </p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                The brief was to create a visual identity for a mock
                Shakespearean play, with the challenge being to weave
                Singaporean elements into the identity. Subsequently, the
                identity was to be applied onto a Show Poster, a Media Kit & a
                Style Guide.
                <br />
                <br />I chose to work on the story of
                <span className="fw-bold">Macbeth</span>. The visual elements I
                played with were:
                <br />
                <br />
                <span className="fw-bold">Blood:</span> symbolising the violence
                of the tale. The lead character deteriorates from a noble
                warrior to a paranoid murderer as the story progresses.
                <br />
                <br />
                <span className="fw-bold">Gold Cootie-Catcher:</span> a folded
                paper fortune-telling device used as a crown in the play's
                identity. It symbolises Macbeth toying with prophecy & fate to
                be king.
              </p>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col">
              <img src={Shot1} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot2} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <img src={Shot3} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot4} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot5} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <img src={Shot6} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot7} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot8} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot9} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <img src={Shot10} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot11} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot12} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <img src={Shot13} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot14} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-4">
              <img src={Shot15} alt="" class="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default Macbeth;
