import React from "react";
import HeroImage from "../media/projects/shimeji/shimeji_page-cover.jpg";
import Shot1 from "../media/projects/shimeji/Shimeji_Logos.png";
import Shot2 from "../media/projects/shimeji/Shimeji_3FrameIG-FINAL-04.jpg";
import Shot3 from "../media/projects/shimeji/Shimeji_Menu-200423.jpg";
import Shot4 from "../media/projects/shimeji/Shimeji_Classics-Shimeji & Meatball_RED.jpg";
import Shot5 from "../media/projects/shimeji/Shimeji_Classics-Shimeji & Salmon_YELLOW.jpg";
import Shot6 from "../media/projects/shimeji/Shimeji_Signature-Shimeji Truffle Cheese_CORAL.jpg";
import Shot7 from "../media/projects/shimeji/Shimeji_Classics-Shimeji & Beef_CORAL.jpg";
import Shot8 from "../media/projects/shimeji/Shimeji_Classics-Shimeji & Chicken_CREAM.jpg";
import Shot9 from "../media/projects/shimeji/Shimeji_Signature-Shimeji & Crabmeat_RED.jpg";
import BackTo from "../components/BackTo";

function Shimeji() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Shimeji subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                Shimeji
                <br />
                (Proposed)
              </h3>
              <p className="project-tag">
                Art Direction | Branding | Illustration
              </p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">Shimeji </span>was to be a
                Japanese-Italian fusion concept (now defunct).
                <br />
                <br />
                The brief was to give the brand a minimalistic and solid-colour
                approach. Flat illustrations were included into the art
                direction too, to evoke a tinge of playfulness.
              </p>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col">
              <img src={Shot1} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot2} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-4">
              <img src={Shot3} alt="" class="img-fluid mb-4" />
            </div>

            <div class="col-6 col-lg-3 mb-4">
              <img src={Shot4} alt="" class="img-fluid mb-4" />
              <img src={Shot5} alt="" class="img-fluid mb-4" />
              <img src={Shot6} alt="" class="img-fluid mb-4" />
            </div>

            <div class="col-6 col-lg-3 mb-4">
              <img src={Shot7} alt="" class="img-fluid mb-4" />
              <img src={Shot8} alt="" class="img-fluid mb-4" />
              <img src={Shot9} alt="" class="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default Shimeji;
