import React from "react";
import MusicImage1 from "../media/self/guitar-shot-1 copy 2.jpg";
import MusicImage2 from "../media/self/guitar-shot-3.jpg";

function MusicImages() {
  return (
    <div className="music-images container-fluid mt-5">
      <div className="row">
        <div className="col-md-6 mb-4">
          <img
            src={MusicImage1}
            alt="Sarah Kaydence_music shot 1"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6 mb-4">
          <img
            src={MusicImage2}
            alt="Sarah Kaydence_music shot 1"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
}

export default MusicImages;
