import React from "react";
import { Link } from "react-router-dom";
import Tag from "../media/favicons/tag_BK.png";
import "../styles/Navbar.css";

function Navbar() {
  return (
    <nav
      className="navbar sticky-top navbar-expand-lg color-nav"
      variant="dark"
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" title="Home page">
          <img src={Tag} alt="Sarah Kaydence - Logo Tag" id="site-logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end menu-tabs"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <Link
              className="nav-link"
              id="nav-about"
              to="/about"
              title="About page"
            >
              About
            </Link>
            <Link
              className="nav-link"
              id="nav-artdesign"
              to="/artdesign"
              title="Art & Design page"
            >
              Art & Design
            </Link>
            <Link
              className="nav-link"
              id="nav-music"
              to="/music"
              title="Music page"
            >
              Music
            </Link>

            <a
              className="nav-link"
              href="https://www.instagram.com/sarahkaydence/"
              target="_blank"
              rel="noreferrer"
              title="Instagram link"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="nav-link"
              href="https://www.linkedin.com/in/sarahkaydence/"
              target="_blank"
              rel="noreferrer"
              title="LinkedIn link"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              className="nav-link"
              href="https://github.com/sarahkydnc"
              target="_blank"
              rel="noreferrer"
              title="GitHub link"
            >
              <i className="fab fa-github"></i>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
