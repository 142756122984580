import React from "react";
import { Link } from "react-router-dom";
import ThumbnailCampXL from "../media/projects/camp-xl/camp-xl_cover-image.jpg";
import Thumbnail90Secs from "../media/projects/90-seconds/90-seconds_logo.svg";
import ThumbnailLKYSPP from "../media/projects/lkyspp/lkyspp_cover-image.jpg";
import ThumbnailOI from "../media/projects/odyssey-iliad/odyssey-iliad_cover-image.JPG";

function ArtDesignRow5() {
  return (
    <div className="design-row row text-center">
      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/campxl"
          title="Camp XL page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailCampXL}
              alt="project_camp XL"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">Camp XL</h4>
        <small class="feature-category">
          Art Direction | Branding | Graphic Design
        </small>
      </div>

      <div className="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/90seconds"
          title="90 Seconds page"
        >
          <div className="wrapper">
            <img
              src={Thumbnail90Secs}
              alt="project_90 seconds"
              className="img-fluid"
            />
          </div>
        </Link>

        <h4 className="mt-4 mb-2">90 Seconds</h4>
        <small className="feature-category">Presentation Design</small>
      </div>

      <div className="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/lkyspp"
          title="LKYSPP page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailLKYSPP}
              alt="project_lee kuan yew school of public policy"
              className="img-fluid"
            />
          </div>
        </Link>

        <h4 className="mt-4 mb-2">
          Lee Kuan Yew
          <br />
          School of Public Policy
        </h4>
        <small className="feature-category">
          Editorial Design | Book Design
        </small>
      </div>

      <div className="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/odysseyiliad"
          title="The Odyssey and The Iliad page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailOI}
              alt="project_the odyssey and the iliad"
              className="img-fluid"
            />
          </div>
        </Link>

        <h4 className="mt-4 mb-2">The Odyssey and The Iliad</h4>
        <small className="feature-category">Book Design | Illustration</small>
      </div>
    </div>
  );
}

export default ArtDesignRow5;
