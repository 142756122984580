import React from "react";
import HeadShot from "../media/self/head-shot.jpg";
import Resume from "../media/self/SarahKaydenceOng_Resume.pdf";

function AboutIntro() {
  return (
    <div className="about-intro p-3">
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col col-lg-4 p-5 justify-content-center">
            <img
              src={HeadShot}
              alt="Sarah Kaydence_portrait"
              className="img-fluid portrait-shot d-none d-lg-block"
              id="head-shot"
            />
          </div>
          <div className="col-lg-8 p-5">
            <div className="intro-text-column p-4 intro-card">
              <div className="mb-3 about-header">
                <h1 className="text-uppercase">Hi, I'm Sarah!</h1>
                <h3>
                  I'm a multi-hatted creative, born and bred in Singapore.
                </h3>
              </div>
              <p className="about-paragraph">
                My creative expertise encompasses various mediums, such as
                illustration, print design and web design.
                <br />
                Being a curious thinker and appreciator of both analog and
                digital formats — I'm eager to discover how both can be used in
                creative ways, to deliver unique yet meaningful solutions to
                service the needs of clients and everyday life.
                <br />
                <br />
                A graphic designer by training and self-taught
                illustrator/musician — I've worked in creative studios,
                start-ups and global-sized companies from various industries for
                the past 8 years (and counting!)
                <br />
                I'm also currently serving as a designer and music
                director/guitarist in my local church's creative and worship
                ministries.
                <br />
                <br />
                <span className="fw-bold text-uppercase">Wanna team up?</span>
                <br />
                Flick me an&nbsp;
                <a
                  href="mailto:sarahkydnc@gmail.com"
                  className="fw-bold click-link"
                >
                  email
                </a>
                &nbsp;for any enquiries or opportunities! My&nbsp;
                <a
                  href={Resume}
                  download="sarah-kaydence_resume"
                  className="fw-bold click-link"
                >
                  resume
                </a>
                &nbsp;is available for download too!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutIntro;
