import React from "react";
import HeroImage from "../media/projects/clement-elysia/clement-elysia_actual-1.jpeg";
import Shot1 from "../media/projects/clement-elysia/clement-elysia_actual-4.jpg";
import Shot2 from "../media/projects/clement-elysia/clement-elysia_actual-2.jpeg";
import Shot3 from "../media/projects/clement-elysia/clement-elysia_actual-3.jpeg";
import BackTo from "../components/BackTo";

function ClemElysia() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Clement and Elysia subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">Clement and Elysia</h3>
              <p className="project-tag">Illustration</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                A churchmate of mine approached me in 2021 to create an
                illustration of him and his (then-girlfriend-now) fiancé, for
                his proposal date.
                <br />
                <br />
                Human-centric illustrations have long been a challenge to me,
                but I decided to give it a shot anyway. Turns out... the final
                product came out better than I thought!
                <br />
                <br />
                He liked it. She liked it. I liked it. It was a triple-win!
                *Fist-pumps into the air*
                <br />
                <br />
                (Photo credits: Clement Tan)
              </p>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-lg-6 mb-4">
              <img src={Shot1} alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6 mb-4">
              <img src={Shot2} alt="" class="img-fluid" />
              <img src={Shot3} alt="" class="img-fluid mt-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default ClemElysia;
