import React from "react";
import HeroImage from "../media/projects/comic-connect/smrt-somerset_page-cover.jpg";
import Shot1 from "../media/projects/comic-connect/smrt-somerset_zoom 1.jpg";
import Shot2 from "../media/projects/comic-connect/smrt-somerset_zoom 2.jpg";
import Shot3 from "../media/projects/comic-connect/smrt-somerset_zoom 3.jpg";
import BackTo from "../components/BackTo";

function ComicConnect() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Comic Connect subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                Comic Connect:
                <br />
                Somerset
              </h3>
              <p className="project-tag">Illustration</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">Comic Connect </span>is a mural series
                initiated by SMRT, in conjunction with their 35th anniversary.
                Collaborating with local artists to install such artworks across
                35 train stations — each mural will depict the history and
                culture of the neighourhood it is in.
                <br />
                <br />
                For the station
                <span className="fw-bold"> Somerset</span> — my co-illustrators
                and I decided to to create a mural that captures the
                youthfulness and vibrancy of Somerset's street culture, while
                juxtaposing new and old historical buildings located in the
                area.
                <br />
                <br />
                Some historical landmarks featured in the mural include: The
                Heeren, Mandarin Orchard, Emerald Hill and Youth Park.
              </p>

              <br />
              <a
                href="https://www.straitstimes.com/singapore/smrt-unveils-series-of-murals-depicting-history-of-neighbourhoods"
                target="_blank"
                rel="noreferrer"
                title="Link to Comic Connect news feature"
                className="project-button text-decoration-none fw-bold"
              >
                Comic Connect News Feature
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-4 mb-4">
              <img src={Shot1} alt="" className="img-fluid" />
            </div>
            <div className="col-4 mb-4">
              <img src={Shot2} alt="" className="img-fluid" />
            </div>
            <div className="col-4 mb-4">
              <img src={Shot3} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default ComicConnect;
