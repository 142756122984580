import React from "react";
import HeroImage from "../media/projects/90-seconds/90-seconds_page-cover.jpg";
import Shot1 from "../media/projects/90-seconds/90-seconds_cover-image.jpg";
import Shot2 from "../media/projects/90-seconds/90 for Brands - Enterprises-5.jpg";
import Shot3 from "../media/projects/90-seconds/90 for Brands - Agencies-2.jpg";
import Shot4 from "../media/projects/90-seconds/90 for Brands - Agencies-5.jpg";
import Shot5 from "../media/projects/90-seconds/90 for Brands - Marketing Teams-3.jpg";
import Shot6 from "../media/projects/90-seconds/90 for Brands - Regional Teams-2.jpg";
import BackTo from "../components/BackTo";

function NinetySeconds() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Camp XL subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">90 Seconds</h3>
              <p className="project-tag">Presentation Design</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">90 Seconds </span>is a global video
                creation platform, enabling the world's biggest brands to create
                high-quality and authentic videos anywhere in the world.
                <br />
                <br />
                During my tenure at 90 Seconds, I created presentation decks of
                the various "For Brands" sections, based off content from the
                company's website. These decks were to be eventually used by the
                Growth team, when it came to pitching to brands on how the
                platform can help them with their video production.
                <br />
                <br />
                Below are some slides from the various decks.
              </p>
              <br />
              <a
                href="https://90seconds.com"
                target="_blank"
                rel="noreferrer"
                title="Link to 90 Seconds' website"
                className="project-button text-decoration-none fw-bold"
              >
                90seconds.com
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6">
              <img src={Shot1} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot2} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img src={Shot3} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot4} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <img src={Shot5} alt="" className="img-fluid mb-4" />
            </div>
            <div className="col-lg-6">
              <img src={Shot6} alt="" className="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default NinetySeconds;
