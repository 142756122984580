import React from "react";
import BackTo from "../components/BackTo";
import HeroImage from "../media/projects/starwars-landing-page/starwars-landing-page_web-mock.png";

function SWPage() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Landing Page subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                Star Wars
                <br />
                Landing Page
              </h3>
              <p className="project-tag">HTML | CSS | VanillaJS</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">SheCodes Basics </span>is a 3-week
                part-time course that I attended in October 2021. I learnt about
                the basics of coding, and how to utilise HTML, CSS and
                Javascript to build simple web pages.
                <br />
                <br />
                I built this Star Wars-themed landing page, as my graduating
                final project for the course.
                <br />
                <br />
                <span className="fw-bold">Languages: </span>HTML5, CSS, and
                Javascript
              </p>
              <br />
              <a
                href="https://www.shecodes.io/workshops/shecodes-basics-13fad4d1-f7de-40b5-88b1-497dbc2e10af/projects/552126"
                target="_blank"
                rel="noreferrer"
                title="Link to Star Wars Landing Page"
                className="project-button text-decoration-none fw-bold"
              >
                Give the page a spin!
              </a>
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default SWPage;
