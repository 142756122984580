import React from "react";
import HeroImage from "../media/projects/13-honey/13-honey_page-cover.jpg";
import Shot1 from "../media/projects/13-honey/13-honey_cover-image.jpg";
import Shot2 from "../media/projects/13-honey/13H_NDP Promo_B2C-Square.jpg";
import Shot3 from "../media/projects/13-honey/13H_NDP-IG_210728.jpg";
import Shot4 from "../media/projects/13-honey/13H_5TH ANNI-Square-1_210825.jpg";
import Shot5 from "../media/projects/13-honey/13H X'mas_GP5 (Decor-FL).jpg";
import Shot6 from "../media/projects/13-honey/13H X'mas_RS-Single (Decor).jpg";
import Shot7 from "../media/projects/13-honey/13 Honey_IG Oct_Halloween.jpg";
import Shot8 from "../media/projects/13-honey/13 Honey_IG Mar_Red.jpg";
import Shot9 from "../media/projects/13-honey/13 Honey_IG Mar_Peach.jpg";
import Shot10 from "../media/projects/13-honey/13 Honey_IG Mar_Lemon.jpg";
import Shot11 from "../media/projects/13-honey/13 Honey_IG Mar_Yellow.jpg";
import Shot12 from "../media/projects/13-honey/13 Honey_IG Mar_Lime.jpg";
import Shot13 from "../media/projects/13-honey/13 Honey_IG Mar_Green.jpg";
import Shot14 from "../media/projects/13-honey/13 Honey_IG Mar_Teal.jpg";
import Shot15 from "../media/projects/13-honey/13 Honey_IG Mar_Blue.jpg";
import Shot16 from "../media/projects/13-honey/13 Honey_IG Mar_Purple.jpg";
import Shot17 from "../media/projects/13-honey/13 Honey_Brochure(2022)-1.jpg";
import Shot18 from "../media/projects/13-honey/13 Honey_Brochure(2022)-2.jpg";
import BackTo from "../components/BackTo";

function ThirteenHoney() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of 13 Honey subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">13 Honey</h3>
              <p className="project-tag">
                Graphic Design | Art Direction | Digital Imaging
              </p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">13 Honey </span>is a Singaporean honey
                brand, of which all of their honey products are harvested &
                produced from their very own bee farm. From beekeeping to
                distribution, each process is carried out to the highest
                standards — to ensure that their honey is kept in its most
                excellent condition all the way to consumers.
              </p>
              <br />
              <a
                href="https://www.instagram.com/13honeysg/"
                target="_blank"
                rel="noreferrer"
                title="Link to 13 Honey's Instagram"
                className="project-button text-decoration-none fw-bold"
              >
                @13honeysg
              </a>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot1} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot2} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot3} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot4} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot5} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot6} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot7} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot8} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot9} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot10} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot11} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot12} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot13} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot14} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot15} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4">
              <img src={Shot16} alt="" className="img-fluid" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <img src={Shot17} alt="" className="img-fluid mb-4" />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <img src={Shot18} alt="" className="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default ThirteenHoney;
