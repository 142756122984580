import React from "react";
import { Link } from "react-router-dom";
import ThumbnailWeather from "../media/projects/weather-app/weather-app_cover-image.png";
import ThumbnailRespWeb from "../media/projects/responsive-portfolio/portfolio-website_cover-image.png";
import ThumbnailBasicsPg from "../media/projects/starwars-landing-page/starwars-landing-page_cover-image.png";
import ThumbnailMBP from "../media/projects/music-box-pedals/music-box-pedals_cover-image.png";

function ArtDesignRow2() {
  return (
    <div class="design-row row text-center">
      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/vanillaweather"
          title="Weather App page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailWeather}
              alt="project_weather app"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">
          Weather App
          <br />
          (VanillaJS)
        </h4>
        <small class="feature-category">
          HTML | Bootstrap CSS | VanillaJS | AJAX
        </small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/responsiveportfolio"
          title="Responsive Portfolio page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailRespWeb}
              alt="project_responsive portfolio website"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">Responsive Portfolio</h4>
        <small class="feature-category">HTML | Bootstrap CSS | Flexbox</small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/starwarslandingpage"
          title="Star Wars landing page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailBasicsPg}
              alt="project_star wars landing page"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">Star Wars Landing Page</h4>
        <small class="feature-category">HTML | CSS | VanillaJS</small>
      </div>

      <div class="col-md-3 mb-5">
        <Link
          className="wrapper-link"
          to="/artdesign/musicboxpedals"
          title="Music Box Pedals page"
        >
          <div className="wrapper">
            <img
              src={ThumbnailMBP}
              alt="project_music box pedals"
              class="img-fluid"
            />
          </div>
        </Link>

        <h4 class="mt-4 mb-2">Music Box Pedals</h4>
        <small class="feature-category">Illustration</small>
      </div>
    </div>
  );
}

export default ArtDesignRow2;
