import React from "react";
import { Link } from "react-router-dom";

function BackTo() {
  return (
    <div className="back-to py-5">
      <Link
        to="/artdesign"
        title="Back to all projects"
        className="back-to-projects"
      >
        Back to all projects
      </Link>
    </div>
  );
}

export default BackTo;
