import React from "react";
import "../styles/Footer.css";

function Footer() {
  return (
    <footer className="m-5">
      <small>
        © 2022, Sarah Kaydence | Built using{" "}
        <a
          className="footer-link"
          href="https://reactjs.org"
          title="Link to React"
        >
          React
        </a>{" "}
        and{" "}
        <a
          className="footer-link"
          href="https://www.netlify.com"
          title="Link to Netlify"
        >
          Netlify
        </a>
      </small>
    </footer>
  );
}

export default Footer;
