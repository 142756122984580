import React from "react";
import HeroImage from "../media/projects/ebil-devices/ebil-devices_page-cover.jpg";
import Shot1 from "../media/projects/ebil-devices/ebil-devices_pedal-1.jpg";
import Shot2 from "../media/projects/ebil-devices/ebil-devices_pedal-2.jpg";
import Shot3 from "../media/projects/ebil-devices/ebil-devices_pedal-3.jpg";
import Shot4 from "../media/projects/ebil-devices/ebil-devices_box-1.jpg";
import Shot5 from "../media/projects/ebil-devices/ebil-devices_box-2.jpg";
import Shot6 from "../media/projects/ebil-devices/ebil-devices_box-3.jpg";
import Shot7 from "../media/projects/ebil-devices/ebil-devices_manual.jpg";
import Shot8 from "../media/projects/ebil-devices/ebil-devices_characters.jpg";
import BackTo from "../components/BackTo";

function EbilDevices() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of Ebil Devices subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">Ebil Devices</h3>
              <p className="project-tag">Packaging Design | Illustration</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold fst-italic">
                  “Ebil: Evil beyond evil. A cute, fuzzy thing gone terribly,
                  terribly wrong."
                </span>
                <br />
                <br />
                <span className="fw-bold">Ebil Devices </span>is a mock guitar
                pedal company I came up with, that manufactures pedals with
                illustrations based off geek and pop culture (i.e. robots,
                aliens, monsters, superheroes).
                <br />
                <br />
                Effect pedals have long been tools of guitar players for
                decades. But somehow, most of them are not all attractive when
                it comes to the aesthetics of the product, be it the pedal
                itself, or the packaging. As a lover of good tone and rad
                design, Ebil Devices was borne out an desire to create pedals
                that are not only eye-catching, but ear-grabbing too.
                <br />
                <br />
                Featured below is a sample boost pedal called the
                <span className="fst-italic"> "Moonhowler"</span>, of which was
                initially created as part of a Halloween-inspired series of
                pedals.
              </p>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col">
              <img src={Shot1} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <img src={Shot2} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-6">
              <img src={Shot3} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <img src={Shot4} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-6">
              <img src={Shot5} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <img src={Shot6} alt="" class="img-fluid mb-4" />
            </div>
            <div class="col-lg-6">
              <img src={Shot7} alt="" class="img-fluid mb-4" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <img src={Shot8} alt="" class="img-fluid mb-4" />
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default EbilDevices;
