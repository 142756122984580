import React from "react";
import { Link } from "react-router-dom";

function Welcome() {
  return (
    <div className="home-hero m-5">
      <h1>Hello there.</h1>
      <br />
      <h2>I'm Sarah Kaydence.</h2>
      <h3 className="mt-5">Designer | Illustrator | Coder | Guitar Player</h3>

      <div className="navigation-links mb-5">
        <Link to="/about" title="About page" id="about-link">
          More about me
        </Link>

        <Link to="/artdesign" title="Art & Design page" id="work-link">
          Things I've designed
        </Link>

        <Link to="/music" title="Music page" id="music-link">
          Tunes I've played
        </Link>
      </div>
    </div>
  );
}

export default Welcome;
