import React from "react";
import BackTo from "../components/BackTo";
import HeroImage from "../media/projects/react-dictionary-app/react-dictionary-app_web-mock.png";

function ReactDictionary() {
  return (
    <div className="project-page">
      <section className="project-main mb-5">
        <div className="container-fluid">
          <img
            src={HeroImage}
            alt="Cover of React Dictionary App subpage"
            className="project-main-image img-fluid mt-5"
          />

          <div className="row mt-5">
            <div className="col-sm-6">
              <h3 className="project-title">
                Dictionary App
                <br />
                (React.js)
              </h3>
              <p className="project-tag">HTML | CSS | React.js | AJAX</p>
            </div>

            <div className="col-sm-6">
              <p className="project-description">
                <span className="fw-bold">SheCodes React </span>is a 8-week
                part-time course that I attended, from March to April 2022.
                During the course, I learnt about the fundamentals of React — an
                in-demand Javascript framework — as well as how it makes web
                applications or websites easier to build and maintain, as
                compared to using VanillaJS.
                <br />
                <br />
                I built this dictionary app, as one of my graduating final
                projects for the course.
                <br />
                <br />
                <span className="fw-bold">Languages: </span>HTML5, CSS, and
                Javascript
                <br />
                <span className="fw-bold">Libraries / Frameworks: </span>React,
                Node.js, Bootstrap
                <br />
                <span className="fw-bold">API: </span>
                <a
                  href="https://dictionaryapi.dev"
                  target="_blank"
                  rel="noreferrer"
                  title="Link to dictionary API"
                  className="portfolio-link"
                >
                  dictionaryapi.dev
                </a>
              </p>
              <br />
              <a
                href="https://idyllic-sorbet-1f30d3.netlify.app/"
                target="_blank"
                rel="noreferrer"
                title="Link to React Dictionary App"
                className="project-button text-decoration-none fw-bold"
              >
                Give the app a try!
              </a>
            </div>
          </div>
        </div>
      </section>

      <BackTo />
    </div>
  );
}

export default ReactDictionary;
